// type Size {
//     sm: string
//     md: string
//     lg: string
//     xl: string
//     xxl: string
//   }

// const size: Size = {
const size = {
  m: "768px", // for tablets
  l: "1280px", // for laptops
};

const Queries = {
  m: `only screen and (min-width: ${size.m})`,
  l: `only screen and (min-width: ${size.l})`,
};

export default Queries;
