import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import SlimHero from "../../Components/SlimHero/SlimHero";
import Section from "../../Components/Section/Section";
import artworks from "../../data/artworks.json";
import Button from "../../Components/Button/Button";
import RightArrowIcon from "../../Icons/RightArrow.svg";
import DigitalPreview from "../../Components/DigitalPreview/DigitalPreview";
import RadioButton from "../../Components/RadioButton/RadioButton";
import Money, { MoneySize } from "../../Components/Money/Money";
import { paletteType, PALETTE } from "./DetailsPage";
import Queries from "../../Utils/MediaQueries";

const Type = {
  DIGITAL: "DIGITAL",
  PRINT: "PRINT",
};

const Size = {
  SMALL: "SMALL",
  MEDIUM: "MEDIUM",
  LARGE: "LARGE",
  XLARGE: "XLARGE",
};

const StyledSection = styled(Section)`
  min-height: 100vh;
  padding-bottom: 100px;
  > div {
    padding-top: 32px;
    display: flex;
    flex-direction: column-reverse;
    gap: 32px;
    margin-bottom: 150px;

    @media ${Queries.m} {
      margin-bottom: 48px;
      flex-direction: row;
    }
  }
`;

const StyledSelectionCol = styled.div`
  width: 100%;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  @media ${Queries.m} {
    width: 50%;
    flex-basis: 50%;
    align-items: flex-start;
  }
`;

const StyledSelection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  position: fixed;
  bottom: 20%;
  background-color: var(--white);
  z-index: 2;
  @media ${Queries.m} {
    position: static;
  }
`;

const StyledFooterSection = styled(Section)`
  background-color: var(--black);
  padding: 16px;
  z-index: 3;
  min-height: 100px;
  position: fixed;
  bottom: 0;
`;

const StyledFooter = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  @media ${Queries.m} {
    flex-direction: row;
  }
`;

const StyledFooterEl = styled.div`
  width: 100%;
  flex-basis: 100%;
  @media ${Queries.m} {
    width: 50%;
    flex-basis: 50%;
  }
`;

const StyledFooterElRow = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 32px;
  margin-bottom: 16px;
  @media ${Queries.m} {
    gap: 0;
  }
`;

const StyledDigitalPreview = styled(DigitalPreview)`
  margin: 0 auto;
  width: 80%;
  @media ${Queries.m} {
    margin: 24px auto;
  }
`;

const StyledPrintPreview = styled.img`
  box-shadow: 2px 2px 10px var(--black);
  border: 1px solid var(--black);
  margin: 0 auto;
  width: 80%;
  @media ${Queries.m} {
    margin: 24px auto;
  }
`;

const CustomisePage = () => {
  const { slug } = useParams();
  const artwork = artworks.find((a) => Number(a.id).toString() === slug);

  const [price, setPrice] = useState(1000);
  const [type, setType] = useState(Type.DIGITAL);
  const [size, setSize] = useState(Size.SMALL);
  const [palette, setPalette] = useState(artwork?.palettes[0]);
  const navigate = useNavigate();

  const updatePalette = useCallback((p: string) => {
    setPalette(p);
  }, []);

  const vat = useMemo(() => Math.floor(price * 0.25), [price]);
  const total = useMemo(() => price + vat, [price, vat]);

  const img = useMemo(
    () => require(`./${artwork?.name}-${palette}-thumb.jpg`),
    [palette],
  );

  return (
    <>
      <SlimHero title="Anpassa ditt fotografi" />
      <StyledSection whiteBg fullWidth={false}>
        <StyledSelectionCol>
          <StyledSelection>
            <h4>Välj typ av leverans</h4>
            <RadioButton
              label="Digital (1 000 kr)"
              id="digital"
              group="type"
              checked={type === Type.DIGITAL}
              onClick={() => {
                setType(Type.DIGITAL);
                setPrice(1000);
              }}
            />
            <RadioButton
              label="Print (Från 1 500 kr)"
              id="print"
              group="type"
              checked={type === Type.PRINT}
              onClick={() => {
                setType(Type.PRINT);
                setPrice(1500);
              }}
            />
          </StyledSelection>
          <StyledSelection>
            <h4>Välj utförande</h4>
            {artwork?.palettes.map((p) => (
              <RadioButton
                key={p}
                label={PALETTE[p as keyof paletteType]}
                id={p}
                group="palette"
                checked={p === palette}
                onClick={() => {
                  updatePalette(p);
                }}
              />
            ))}
          </StyledSelection>
          {type === Type.PRINT && (
            <StyledSelection>
              <h4>Välj storlek</h4>
              <RadioButton
                label="Liten (50cm x 50cm)"
                id="size_small"
                group="size"
                checked={size === Size.SMALL}
                onClick={() => {
                  setSize(Size.SMALL);
                  setPrice(1500);
                }}
              />

              <RadioButton
                label="Medium (100cm x 100cm) + 1000 kr"
                id="size_medium"
                group="size"
                checked={size === Size.MEDIUM}
                onClick={() => {
                  setSize(Size.MEDIUM);
                  setPrice(2500);
                }}
              />
              <RadioButton
                id="size_large"
                label="Stor (150cm x 150cm) + 2000 kr"
                group="size"
                checked={size === Size.LARGE}
                onClick={() => {
                  setSize(Size.LARGE);
                  setPrice(3500);
                }}
              />
              <RadioButton
                id="size_xlarge"
                group="size"
                label="Extra Stor (300cm x 300cm) + 4000 kr"
                checked={size === Size.XLARGE}
                onClick={() => {
                  setSize(Size.XLARGE);
                  setPrice(5500);
                }}
              />
            </StyledSelection>
          )}
        </StyledSelectionCol>
        <StyledSelectionCol>
          {type === Type.DIGITAL && (
            <>
              <StyledDigitalPreview
                image={img}
                width={artwork?.size?.width}
                height={artwork?.size?.height}
              />
              <p>
                Få bilden digitalt direkt till din e-post som högupplöst
                JPEG-fil för full frihet att själv välja leverantör och utseende
                för din print.
              </p>
              <p>
                Verket är upphovsrättsskyddad enligt XXX. Digital leverans är
                avsedd till att enbart användas av bolaget för intern bruk och
                får t. ex. inte kopieras, användas i marknadsföringssyften,
                säljas vidare eller distrubieras vidare till annan part.
              </p>
            </>
          )}
          {type === Type.PRINT && (
            <>
              <StyledPrintPreview src={img} alt="print" />
              <p>
                Bilden skrivs ut på högkvalitativt material på matt aluminium.
                Detta säkerställer att bilden får den bästa lystern och
                minimerar reflektioner och glans från andra ljuskällor vilket
                reducerar bildens kontrast och framhäver detaljer som annars
                skulle försvinna.
              </p>
            </>
          )}
        </StyledSelectionCol>
      </StyledSection>
      <StyledFooterSection fullWidth={false}>
        <StyledFooter>
          <StyledFooterEl>
            <StyledFooterElRow>
              <Money label="Pris" value={price} size={MoneySize.MEDIUM} />
              <Money label="Moms" value={vat} size={MoneySize.MEDIUM} />
              <Money label="Totalt" value={total} size={MoneySize.LARGE} />
            </StyledFooterElRow>
            <Button
              text="Ange Uppgifter"
              icon={RightArrowIcon}
              onClick={() => navigate(`/checkout/`)}
            />
          </StyledFooterEl>
        </StyledFooter>
      </StyledFooterSection>
    </>
  );
};

export default CustomisePage;
