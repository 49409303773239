import styled from "styled-components";

type Props = {
  id: string;
  label: string;
  group: string;
  checked: boolean;
  onClick: () => void;
};

type LabelProps = {
  selected: boolean;
};

const StyledLabel = styled.label<LabelProps>`
  display: flex;
  align-items: flex-start;
  font-size: 20px;
  line-height: 38px;
  position: relative;
  margin-bottom: 8px;
  &:before {
    content: " ";
    margin-top: 3px;
    display: block;
    margin-right: 8px;
    border: 2px solid var(--black);
    min-width: 26px;
    min-height: 26px;
    border-radius: 20px;
    z-index: 3;
  }
  &:after {
    content: " ";
    display: ${(props) => (props.selected ? "block" : "none")};
    position: absolute;
    top: 9px;
    left: 6px;
    z-index: 2;
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 15px;
    background-color: ${(props) =>
      props.selected ? "var(--black)" : "var(--white)"};
  }
  &:hover {
    cursor: pointer;
  }
`;

const StyledInput = styled.input`
  display: none;
`;

const StyledLabelName = styled.span`
  display: flex;
  line-height: 30px;
`;

const RadioButton = ({ id, label, group, checked, onClick }: Props) => {
  return (
    <StyledLabel htmlFor={id} selected={checked}>
      <StyledInput
        type="radio"
        id={id}
        name={group}
        checked={checked}
        onChange={onClick}
      />
      <StyledLabelName>{label}</StyledLabelName>
    </StyledLabel>
  );
};

export default RadioButton;
