import styled from "styled-components";

type Props = {
  className?: string;
  children?: any;
  fullWidth?: boolean;
  whiteBg?: boolean;
};

type SectionProps = {
  $whitebg?: boolean;
};

const StyledSection = styled.div<SectionProps>`
  margin: 0 auto;
  width: 100%;
  background-color: ${(props) => (props.$whitebg ? "var(--white)" : "")};
  color: ${(props) => (props.$whitebg ? "var(--black)" : "var(--white)")};
`;

const StyledContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
`;

const Section = ({
  className,
  children,
  fullWidth = true,
  whiteBg = false,
}: Props) => (
  <StyledSection $whitebg={whiteBg} className={className}>
    {fullWidth ? children : <StyledContainer>{children}</StyledContainer>}
  </StyledSection>
);

export default Section;
