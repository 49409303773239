import styled from "styled-components";

type Props = {
  className?: string;
  image: string;
  width?: number;
  height?: number;
};

const StyledDigitalPreview = styled.div`
  width: 80%;
  position: relative;
  text-align: center;
`;

const StyledLabel = styled.div`
  position: absolute;
  border-radius: 10px;
  border: 3px solid var(--yellow);
  color: var(--yellow);
  background-color: var(--white);
  font-size: 32px;
  padding: 16px;
  padding-bottom: 0;
  bottom: 8%;
  left: -60px;
`;

const StyledSize = styled.p`
  color: var(--darkGray);
  font-style: italic;
  margin-bottom: 0;
  margin-top: 8px;
`;

const StyledTimesText = styled.span`
  font-size: 12px;
  position: relative;
  top: -3px;
`;

const DigitalPreview = ({ className, image, width, height }: Props) => (
  <StyledDigitalPreview className={className}>
    <img src={image} alt="Digital preview" />
    <StyledLabel>
      JPEG
      {width && height && (
        <StyledSize>
          {width} <StyledTimesText>x</StyledTimesText> {height} px
        </StyledSize>
      )}
    </StyledLabel>
  </StyledDigitalPreview>
);

export default DigitalPreview;
