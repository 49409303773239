import styled from "styled-components";
import "./App.css";
import Button from "./Components/Button/Button";
import { ButtonVariant } from "./Components/Button/ButtonVariant";
import Section from "./Components/Section/Section";
import SlimHero from "./Components/SlimHero/SlimHero";
import SlimHeroImg from "./Images/SlimHero.jpg";
import SlimHeroImg2 from "./Images/SlimHero2.jpg";
import TileImg from "./Images/ghost.jpg";
import Tile from "./Components/Tile/Tile";
import CartButton from "./Components/CartButton/CartButton";
import Menu from "./Components/Menu/Menu";
import Hero from "./Components/Hero/Hero";
import HeroImg from "./Images/Hero-mob.jpg";
import HeroImgDesktop from "./Images/Hero.jpg";
import UspSection from "./Components/UspSection/UspSection";
import Queries from "./Utils/MediaQueries";
import Input, { InputTypes } from "./Components/Input/Input";
import FilterSection from "./Components/FilterSection/FilterSection";
import DigitalPreview from "./Components/DigitalPreview/DigitalPreview";

const StyledDarkSection = styled(Section)`
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 20px 0;
  flex-direction: column;
  padding: 20px;
  @media ${Queries.m} {
    flex-direction: row;
  }
`;

const StyledLightSection = styled(Section)`
  background-color: var(--white);
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 20px 0;
  flex-direction: column;
  padding: 20px;
  @media ${Queries.m} {
    flex-direction: row;
  }
`;

const App = () => (
  <>
    <Menu />
    <Section>
      <Hero
        text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        title="Vetenskap som konst"
        imageDesktop={HeroImgDesktop}
        image={HeroImg}
        action={() => {}}
        buttonText="Se utbudet"
      />
    </Section>
    <Section>
      <UspSection />
    </Section>
    <StyledDarkSection>
      <Button text="Primary" />
      <Button text="Secondary" variant={ButtonVariant.SECONDARY} />
      <Button text="Attention" variant={ButtonVariant.ATTENTION} />
      <Button text="Warning" variant={ButtonVariant.WARNING} />
      <CartButton variant={ButtonVariant.PRIMARY} />
    </StyledDarkSection>
    <StyledLightSection>
      <Button text="Primary" />
      <Button text="Secondary" variant={ButtonVariant.SECONDARY} />
      <Button text="Attention" variant={ButtonVariant.ATTENTION} />
      <Button text="Warning" variant={ButtonVariant.WARNING} />
      <CartButton variant={ButtonVariant.PRIMARY} />
    </StyledLightSection>
    <Tile
      text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
      title="Lorem ipsum"
      image={TileImg}
      action={() => {}}
      buttonText="Tile button"
    />
    <Tile
      text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
      direction="row-reverse"
      title="Lorem ipsum"
      image={TileImg}
      action={() => {}}
      buttonText="Tile button"
    />
    <SlimHero title="Typography" image={SlimHeroImg2} />
    <Section fullWidth={false}>
      <h1>Header 1</h1>
      <h2>Header 2</h2>
      <h3>Header 3</h3>
      <h4>Header 4</h4>
      <h5>Header 5</h5>
      <h6>Header 6</h6>
      <p>Paragraph text</p>
    </Section>
    <SlimHero title="Filter" image={SlimHeroImg2} />
    <Section fullWidth={false} whiteBg>
      <FilterSection />
      <DigitalPreview image={HeroImg} />
    </Section>
    <SlimHero title="Input fields" image={SlimHeroImg} />
    <StyledLightSection>
      <Input placeholder="Frivilligt fält" />
      <Input placeholder="Obligatorisk fält" required />
      <Input
        required
        inputType={InputTypes.EMAIL}
        placeholder="E-post"
        value="wrong@email"
      />
      <Input
        inputType={InputTypes.ORG_NR}
        placeholder="Organisationsnummer (XXXXXX-XXXX)"
        required
      />
    </StyledLightSection>
  </>
);
export default App;
