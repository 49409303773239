import styled from "styled-components";
import Queries from "../../Utils/MediaQueries";

type Props = {
  title: string;
  image?: HTMLImageElement;
};

type SlimHeroProps = {
  bg: string;
};

const StyledHeader = styled.h2`
  font-size: 24px;
  margin: 0;
  @media ${Queries.m} {
    font-size: 32px;
  }
`;

const StyledSlimHero = styled.div<SlimHeroProps>`
  width: 100%;
  text-align: center;
  background: url(${(props) => props.bg}) no-repeat center;
  background-color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  text-shadow: var(--black) 1px 0 10px;
  background-size: 100% auto;
  animation: bgZoom 180s infinite;
  height: 100px;

  @keyframes bgZoom {
    50% {
      background-size: 130% auto;
    }
  }
  @media ${Queries.m} {
    font-size: 48px;
    height: 200px;
  }
`;

const SlimHero = ({ title = "", image }: Props) => (
  <StyledSlimHero bg={image as unknown as string}>
    <StyledHeader>{title}</StyledHeader>
  </StyledSlimHero>
);

export default SlimHero;
