import React, { useState } from "react";
import styled from "styled-components";

type Props = {
  className?: string;
  children?: React.ReactNode;
};

type OpenProps = {
  menuopen?: boolean;
};

const StyledHamburger = styled.div`
  width: 27px;
  height: 25px;
  z-index: 5;
  position: relative;
  &:hover {
    cursor: pointer;
  }
`;

const StyledTopRow = styled.span<OpenProps>`
  height: 1px;
  position: absolute;
  top: 1px;
  width: 100%;
  display: inline-block;
  height: 2px;
  background-color: var(${(props) => (props.menuopen ? "--blue" : "--white")});
  transform: rotate(${(props) => (props.menuopen ? "135deg" : "0")})
    translate(${(props) => (props.menuopen ? "7px, -7px" : "0")});
  transition: 300ms;
`;

const StyledCenterRow = styled.span<OpenProps>`
  width: 100%;
  height: 2px;
  display: inline-block;
  background-color: var(${(props) => (props.menuopen ? "--blue" : "--white")});
  transform: translateX(${(props) => (props.menuopen ? "25px" : "0")});
  opacity: ${(props) => (props.menuopen ? "0" : "1")};
  transition: 300ms;
`;

const StyledBottomRow = styled.span<OpenProps>`
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  display: inline-block;
  height: 2px;
  background-color: var(${(props) => (props.menuopen ? "--blue" : "--white")});
  transform: rotate(${(props) => (props.menuopen ? "-135deg" : "0")})
    translate(${(props) => (props.menuopen ? "7px, 8px" : "0")});
  transition: 300ms;
`;

const StyledSideMenu = styled.div<OpenProps>`
  z-index: 5;
  background-color: var(--white);
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 80vw;
  transform: translateX(${(props) => (props.menuopen ? "0px" : "80vw")});
  transition: 300ms;
`;
const StyledBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--black);
  z-index: 1;
  opacity: 0.5;
`;

const Hamburger = ({ className, children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClickListener = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <>
      {isOpen && (
        <StyledBackground role="button" onClick={() => setIsOpen(false)} />
      )}
      <StyledSideMenu menuopen={isOpen}>{children}</StyledSideMenu>
      <StyledHamburger className={className} onClick={onClickListener}>
        <StyledTopRow menuopen={isOpen} />
        <StyledCenterRow menuopen={isOpen} />
        <StyledBottomRow menuopen={isOpen} />
      </StyledHamburger>
    </>
  );
};

export default Hamburger;
