import styled from "styled-components";
import Section from "../Section/Section";
import Button from "../Button/Button";
import { ButtonVariant } from "../Button/ButtonVariant";
import Queries from "../../Utils/MediaQueries";

type Props = {
  title: string;
  text: string;
  image: HTMLImageElement;
  action?: () => void;
  buttonText?: string;
  direction?: string;
  variant?: string;
  buttonIcon?: any;
};

type TileProps = {
  direction: string;
};

type ImageProps = {
  bg: string;
};

const StyledTile = styled.div<TileProps>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: var(--white);
  @media ${Queries.m} {
    min-height: 500px;
    flex-direction: ${(props) => props.direction};
  }
`;

const StyledImageContainer = styled.div<ImageProps>`
  background: url(${(props) => props.bg}) no-repeat;
  background-position: center;
  background-size: 100% auto;
  height: auto;
  flex-basis: 100%;
  width: 100%;
  @media ${Queries.m} {
    background-size: 140% auto;
  }
  @media ${Queries.l} {
    background-size: 100% auto;
  }
`;

const StyledImage = styled.img`
  visibility: hidden;
`;

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--black);
  padding: 20px 20px 80px;
  @media ${Queries.m} {
    flex-basis: 55%;
    width: 55%;
    padding: 20px;
  }
`;

const StyledText = styled.p`
  max-width: 600px;
  margin-bottom: 24px;
`;

const Tile = ({
  title,
  text,
  image,
  action,
  buttonText,
  direction = "row",
  variant = ButtonVariant.SECONDARY,
  buttonIcon,
}: Props) => (
  <Section>
    <StyledTile direction={direction}>
      <StyledImageContainer bg={image as unknown as string}>
        <StyledImage src={image as unknown as string} alt="image" />
      </StyledImageContainer>
      <StyledTextContainer>
        <h2>{title}</h2>
        <StyledText dangerouslySetInnerHTML={{ __html: text }} />
        {action && (
          <Button
            icon={buttonIcon}
            variant={variant}
            text={buttonText || "Klick"}
            onClick={action}
          />
        )}
      </StyledTextContainer>
    </StyledTile>
  </Section>
);

export default Tile;
