import { useNavigate } from "react-router-dom";
import Hero from "../../Components/Hero/Hero";
import Menu from "../../Components/Menu/Menu";
import TileImg from "../../Images/ghost.jpg";
import Section from "../../Components/Section/Section";
import Tile from "../../Components/Tile/Tile";
import UspSection from "../../Components/UspSection/UspSection";
import HeroImg from "../../Images/Hero-mob.jpg";
import HeroImgDesktop from "../../Images/Hero.jpg";
import SlimHero from "../../Components/SlimHero/SlimHero";
import SlimHeroImg from "../../Images/SlimHero.jpg";

const StartPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Menu />
      <Section>
        <Hero
          text="Skapa en unik arbetsplats för er och era kunder och erbjud en plats för filosofiska eller vetenskapliga diskussioner samt utforskning med autentiska bilder tagna under svensk himmel."
          title="Vetenskap som konst"
          imageDesktop={HeroImgDesktop}
          image={HeroImg}
          action={() => navigate("/overview")}
          buttonText="Utforska utbudet"
        />
        <UspSection />
        <Tile
          text="Imponera era kunder med unika bilder på universum och ha genast något att prata om inför de viktiga affärsmötena"
          title="Bryt isen direkt"
          image={TileImg}
        />
        <Tile
          direction="row-reverse"
          text="Varje fotografi är noggrannt bearbetat för att framhäva både vetenskapligt data och en skönhet utav dess like. 
          Dessutom följer en noggrann vetenskaplig beskrivning av objektet med tillhörande kuriosa, designat att väcka 
          metafysiska frågor. Så låt er inspiereras av frågor och tankar som väcks av vårt universum"
          title="Låt er förundras"
          image={TileImg}
          action={() => navigate("/details/12")}
          buttonText="Läs mer om bilden"
        />
        <SlimHero title="Varför astrofotografi?" image={SlimHeroImg} />
      </Section>
    </>
  );
};

export default StartPage;
