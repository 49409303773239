import Menu from "../../Components/Menu/Menu";
import SlimHero from "../../Components/SlimHero/SlimHero";

const AboutPage = () => (
  <>
    <Menu />
    <SlimHero title="Om mig" />
  </>
);

export default AboutPage;
