import styled from "styled-components";
import { ButtonVariant } from "./ButtonVariant";
import Queries from "../../Utils/MediaQueries";

type Props = {
  className?: string;
  children?: any;
  text?: string;
  variant?: any;
  onClick?: () => void;
  icon?: any;
};

type ButtonProps = {
  variant: string;
  icon?: any;
};

const StyledButton = styled.button<ButtonProps>`
  border: 0;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.2rem;
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  background-color: var(--blue);
  color: var(--white);

  background-color: ${(props) =>
    props.variant === ButtonVariant.SECONDARY && "transparent"};
  color: ${(props) =>
    props.variant === ButtonVariant.SECONDARY && "var(--blue)"};
  ${(props) =>
    props.variant === ButtonVariant.SECONDARY &&
    "border: 2px solid var(--blue)"};

  background-color: ${(props) =>
    props.variant === ButtonVariant.ATTENTION && "var(--yellow)"};
  color: ${(props) =>
    props.variant === ButtonVariant.ATTENTION && "var(--white)"};

  background-color: ${(props) =>
    props.variant === ButtonVariant.WARNING && "var(--red)"};
  color: ${(props) =>
    props.variant === ButtonVariant.WARNING && "var(--white)"};
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
  &:active {
    opacity: 0.7;
  }
  &:after {
    ${(props) => (props.icon ? 'content: " "' : "")};
    display: block;
    width: 15px;
    height: 15px;
    background: url(${(props) => props.icon}) no-repeat;
    background-position: center;
    background-size: 100%;
  }
  @media ${Queries.m} {
    width: auto;
  }
`;

const Button = ({
  className,
  text,
  variant = ButtonVariant.PRIMARY,
  children,
  onClick,
  icon,
}: Props) => (
  <StyledButton
    className={className}
    variant={variant}
    onClick={onClick}
    icon={icon}
  >
    {text ?? children}
  </StyledButton>
);

export default Button;
