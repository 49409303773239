import { useEffect, useState } from "react";
import styled from "styled-components";
import TheoAvatar from "./avatar-mario.png";
import StellaAvatar from "./avatar-unicorn.png";
import Button from "../../Components/Button/Button";

type StyledPointerProps = {
  deg: number;
};

type StyledPointerTextProps = {
  color: string;
};

type StyledAvatarProps = {
  topPos: number;
  leftPos: number;
};

const STELLA_POS = [
  { top: 40, left: 445 },
  { top: 220, left: 650 },
  { top: 570, left: 600 },
  { top: 630, left: 220 },
  { top: 370, left: 50 },
  { top: 120, left: 120 },
];

const THEO_POS = [
  { top: 140, left: 420 },
  { top: 280, left: 530 },
  { top: 510, left: 510 },
  { top: 550, left: 260 },
  { top: 370, left: 150 },
  { top: 220, left: 220 },
];

const INTERVALS = [
  { text: "Gå på toa.", color: "rgba(50, 136, 189, 1)" },
  { text: "Ta på kläder.", color: "rgba(102, 194, 165, 1)" },
  { text: "Äta frukost.", color: "rgba(171, 221, 164, 1)" },
  { text: "Borsta tänder.", color: "rgba(230, 245, 152, 1)" },
  { text: "Ta på ytterkläder.", color: "rgba(253, 174, 97, 1)" },
  { text: "Gå ut nu!!", color: "rgba(213, 62, 79, 1)" },
];

const INTERVAL_START = 7;
const INTERVAL_DEG = 6;

const INTERVAL_TIMES = {
  "INTERVALS.TOILET": 5,
  "INTERVAL.CLOTHES": 10,
  "INTERVAL.BREAKFAST": 15,
  "INTERVAL.BRUSH_TEETH": 10,
  "INTERVAL.OUTERWEAR": 10,
};

const StyledTimerPage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledTimer = styled.div`
  position: relative;
  width: 800px;
  height: 800px;
  background: conic-gradient(
    rgba(50, 136, 189, 1) 30deg,
    rgba(102, 194, 165, 1) 30deg 90deg,
    rgba(171, 221, 164, 1) 90deg 180deg,
    rgba(230, 245, 152, 1) 180deg 240deg,
    rgba(253, 174, 97, 1) 240deg 300deg,
    rgba(213, 62, 79, 1) 300deg 330deg,
    transparent 330deg 360deg
  );
  border-radius: 50%;
`;

const StyledPointer = styled.div<StyledPointerProps>`
  width: 800px;
  height: 800px;
  background: conic-gradient(
    rgba(0, 0, 0, 0.8) ${(props) => props.deg}deg,
    transparent 0deg 360deg
  );
  border-radius: 50%;
  z-index: 1;
`;

const StyledPointerText = styled.div<StyledPointerTextProps>`
  border-left: 35px solid ${(props) => props.color};
  width: 80%;
  font-size: 30px;
  line-height: 30px;
  margin: 10px 0;
  padding-left: 20px;
`;

const StyledAvatar = styled.img<StyledAvatarProps>`
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  z-index: 3;
  top: ${(props) => props.topPos}px;
  left: ${(props) => props.leftPos}px;
`;

const StyledBottomBar = styled.div`
  display: flex;
`;

const StyledBottomSection = styled.div`
  flex-basis: 33%;
  width: 400px;
`;

const StyledBottomAvatar = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
`;
const StyledButtons = styled.div`
  display: flex;
  width: 150px;
  justify-content: space-between;
`;
const TimerPage = () => {
  const [stellaPos, setStellaPos] = useState(0);
  const [theoPos, setTheoPos] = useState(0);
  const [mins, setMins] = useState(0);

  useEffect(() => {
    const date = new Date();
    const minutes = date.getMinutes();
    setMins(minutes);
    const tickerId = setInterval(() => {
      const d = new Date();
      const m = d.getMinutes();
      setMins(m);
      console.log("ticker found", m);
    }, 10000);
  }, []);

  return (
    <StyledTimerPage>
      <StyledTimer>
        <StyledAvatar
          topPos={THEO_POS[theoPos].top}
          leftPos={THEO_POS[theoPos].left}
          src={TheoAvatar}
          alt="Theo"
        />
        <StyledAvatar
          topPos={STELLA_POS[stellaPos].top}
          leftPos={STELLA_POS[stellaPos].left}
          src={StellaAvatar}
          alt="Stella"
        />
        <StyledPointer deg={mins * 6} />
      </StyledTimer>
      <StyledBottomBar>
        <StyledBottomSection>
          {INTERVALS.map((interval) => (
            <StyledPointerText color={interval.color}>
              {interval.text}
            </StyledPointerText>
          ))}
        </StyledBottomSection>
        <StyledBottomSection>
          <StyledBottomAvatar src={TheoAvatar} alt="Theo" />
          <StyledButtons>
            <Button
              onClick={() => {
                if (theoPos > 0) {
                  setTheoPos(theoPos - 1);
                } else {
                  setTheoPos(5);
                }
              }}
            >
              &lt;
            </Button>
            <Button
              onClick={() => {
                if (theoPos < 5) {
                  setTheoPos(theoPos + 1);
                } else {
                  setTheoPos(0);
                }
              }}
            >
              &gt;
            </Button>
          </StyledButtons>
        </StyledBottomSection>
        <StyledBottomSection>
          <StyledBottomAvatar src={StellaAvatar} alt="Stella" />
          <StyledButtons>
            <Button
              onClick={() => {
                if (stellaPos > 0) {
                  setStellaPos(stellaPos - 1);
                } else {
                  setStellaPos(5);
                }
              }}
            >
              &lt;
            </Button>
            <Button
              onClick={() => {
                if (stellaPos < 5) {
                  setStellaPos(stellaPos + 1);
                } else {
                  setStellaPos(0);
                }
              }}
            >
              &gt;
            </Button>
          </StyledButtons>
        </StyledBottomSection>
      </StyledBottomBar>
    </StyledTimerPage>
  );
};

export default TimerPage;
