import styled from "styled-components";
import Button from "../Button/Button";
import CartIcon from "../../Icons/cart.svg";

type Props = {
  variant: string;
};

type ButtonProps = {
  variant: string;
  icon: string;
};

const StyledButton = styled(Button)<ButtonProps>`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  &:before {
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    background: url(${(props) => props.icon}) no-repeat;
    background-position: center;
    color: var(--white);
  }
`;

const CartButton = ({ variant }: Props) => (
  <StyledButton text="Lägg i varukorgen" variant={variant} icon={CartIcon} />
);

export default CartButton;
