import styled from "styled-components";
import Button from "../Button/Button";
import { ButtonVariant } from "../Button/ButtonVariant";

const StyledFilters = styled.div`
  padding: 20px 0;
  background-color: var(--white);
  color: var(--black);
  display: flex;
`;
const StyledFilterSection = styled.div`
  flex-basis: 50%;
  max-width: 50%;
`;

const StyledFilterButtons = styled.div`
  display: flex;
  gap: 44px;
`;

const StyledButton = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border: none;
  color: var(--black);
`;
const StyledHorisontal = styled.div`
  border: 2px solid var(--black);
  width: 80px;
  height: 60px;
  margin-bottom: 8px;
`;

const StyledVertical = styled.div`
  border: 2px solid var(--black);
  width: 60px;
  height: 80px;
  margin-bottom: 8px;
`;

const StyledSquare = styled.div`
  border: 2px solid var(--black);
  width: 60px;
  height: 60px;
  margin-bottom: 8px;
`;

const FilterSection = () => (
  <StyledFilters>
    <StyledFilterSection>
      <h6>Orientation</h6>
      <StyledFilterButtons>
        <StyledButton variant={ButtonVariant.SECONDARY}>
          <StyledHorisontal />
          Horisontal
        </StyledButton>
        <StyledButton variant={ButtonVariant.SECONDARY}>
          <StyledVertical />
          Vertical
        </StyledButton>
        <StyledButton variant={ButtonVariant.SECONDARY}>
          <StyledSquare />
          Square
        </StyledButton>
      </StyledFilterButtons>
    </StyledFilterSection>
    <StyledFilterSection>
      <h6>Color scheme</h6>
    </StyledFilterSection>
  </StyledFilters>
);

export default FilterSection;
