import styled from "styled-components";
import FrameIcon from "../../Icons/usp-frame.svg";
import TruckIcon from "../../Icons/truck.svg";
import SpeechIcon from "../../Icons/speech.svg";
import Queries from "../../Utils/MediaQueries";

const StyledUspSection = styled.div`
  background-color: var(--black);
  padding: 32px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
  @media ${Queries.m} {
    gap: 200px;
    flex-direction: row;
  }
`;
const StyledUsp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledIcon = styled.img`
  height: 40px;
  @media ${Queries.m} {
    height: 80px;
  }
`;

const StyledText = styled.p`
  margin-top: 10px;
  color: var(--white);
  text-align: center;
`;

const UspSection = () => (
  <StyledUspSection>
    <StyledUsp>
      <StyledIcon src={FrameIcon} alt="Frame" />
      <StyledText>Printar av hög kvalitét</StyledText>
    </StyledUsp>
    <StyledUsp>
      <StyledIcon src={TruckIcon} alt="Frame" />
      <StyledText>Personlig leverans</StyledText>
    </StyledUsp>
    <StyledUsp>
      <StyledIcon src={SpeechIcon} alt="Frame" />
      <StyledText>Skapa en unik arbetsplats</StyledText>
    </StyledUsp>
  </StyledUspSection>
);

export default UspSection;
