import { useMemo } from "react";
import styled from "styled-components";
import Queries from "../../Utils/MediaQueries";

export const MoneySize = {
  SMALL: "SMALL",
  MEDIUM: "MEDIUM",
  LARGE: "LARGE",
};

type Props = {
  value: number;
  size: string;
  label: string;
};

type MoneyProps = {
  size: string;
};

const StyledMoneyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const StyledLabel = styled.p`
  font-size: 16px;
`;

const StyledMoney = styled.div<MoneyProps>`
  ${(props) => props.size === MoneySize.LARGE && "font-size: 24px"};
  ${(props) => props.size === MoneySize.LARGE && "line-height: 24px"};
  ${(props) => props.size === MoneySize.LARGE && "margin-bottom: 12px"};
  ${(props) => props.size === MoneySize.MEDIUM && "font-size: 18px"};
  ${(props) => props.size === MoneySize.SMALL && "font-size: 14px"};
  @media ${Queries.m} {
    ${(props) => props.size === MoneySize.LARGE && "font-size: 52px"};
    ${(props) => props.size === MoneySize.LARGE && "line-height: 34px"};
    ${(props) => props.size === MoneySize.LARGE && "margin-bottom: 32px"};
    ${(props) => props.size === MoneySize.MEDIUM && "font-size: 32px"};
    ${(props) => props.size === MoneySize.SMALL && "font-size: 28px"};
  }
`;

const Money = ({ value, size, label }: Props) => {
  const formatted = new Intl.NumberFormat("sv-SE", {
    style: "currency",
    currency: "SEK",
  }).format(value);
  return (
    <StyledMoneyContainer>
      <StyledLabel>{label}:</StyledLabel>{" "}
      <StyledMoney size={size}>{formatted}</StyledMoney>
    </StyledMoneyContainer>
  );
};

export default Money;
