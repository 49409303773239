import styled from "styled-components";
import Menu from "../../Components/Menu/Menu";
import SlimHero from "../../Components/SlimHero/SlimHero";
import Tile from "../../Components/Tile/Tile";
import SlimHeroImg from "../../Images/SlimHero2.jpg";
import ArtworkImage from "../../Images/ghost.jpg";
import artworks from "../../data/artworks.json";
import Queries from "../../Utils/MediaQueries";

type ImageContainerProps = {
  img: string;
};

const StyledImage = styled.img`
  visibility: hidden;
  width: 100%;
  &:hover {
    opacity: 0.8;
  }
`;

const StyledImageContainer = styled.div<ImageContainerProps>`
  border-bottom: 4px solid var(--white);
  background: url(${(props) => props.img}) no-repeat center center;
  background-size: 100% 100%;
  transition: 800ms;
  &:hover {
    background-size: 102% 102%;
  }
`;

const StyledArtSection = styled.div`
  display: flex;
  padding: 4px;
  gap: 4px;
  flex-direction: column;
  max-width: 2160px;
  margin: 0 auto;
  background-color: var(--white);
  @media ${Queries.l} {
    flex-direction: row;
  }
`;

const StyledArtCol = styled.div`
  display: flex;
  flex-direction: column;
  @media ${Queries.l} {
    flex-basis: 50%;
    width: 50%;
  }
`;

const StyledHeader = styled.h2`
  color: var(--white);
  position: absolute;
  right: 42px;
  bottom: -16px;
  opacity: 0;
  transition: 800ms;
  pointer-events: none;
`;

const StyledImgLink = styled.a`
  position: relative;
  &:hover {
    opacity: 1;
    h2 {
      bottom: 16px;
      opacity: 1;
    }
    border: 0;
  }
`;

const OverviewPage = () => {
  return (
    <>
      <Menu />
      <SlimHero image={SlimHeroImg} title="Fotografier" />
      <StyledArtSection>
        <StyledArtCol>
          {artworks
            ?.slice(0, Math.floor((artworks?.length ?? 0) / 2))
            ?.map((artwork) => {
              const img = require(
                `./${artwork.name}-${artwork?.palettes[0]}-thumb.jpg`,
              );
              return (
                <StyledImgLink key={artwork.id} href={`/details/${artwork.id}`}>
                  <StyledImageContainer img={img}>
                    <StyledImage src={img} alt={artwork?.name} />
                  </StyledImageContainer>
                  <StyledHeader>{artwork.prettyName}</StyledHeader>
                </StyledImgLink>
              );
            })}
        </StyledArtCol>
        <StyledArtCol>
          {artworks
            ?.slice(Math.floor((artworks?.length ?? 0) / 2), artworks?.length)
            ?.map((artwork) => {
              const img = require(
                `./${artwork.name}-${artwork?.palettes[0]}-thumb.jpg`,
              );
              return (
                <StyledImgLink key={artwork.id} href={`/details/${artwork.id}`}>
                  <StyledImageContainer img={img}>
                    <StyledImage src={img} alt={artwork?.name} />
                  </StyledImageContainer>
                  <StyledHeader>{artwork.prettyName}</StyledHeader>
                </StyledImgLink>
              );
            })}
        </StyledArtCol>
      </StyledArtSection>
      <Tile
        image={ArtworkImage}
        title="Hittar du inget som passar?"
        text="Saknar du ett objekt eller en specifik färgskala? Tveka inte att kontakta mig för att specialbeställa ett fotografi som matchar era behov!"
        buttonText="Till kontaktsidan"
        action={() => {}}
      />
    </>
  );
};

export default OverviewPage;
