import { useNavigate, useParams } from "react-router-dom";
import artworks from "../../data/artworks.json";
import Menu from "../../Components/Menu/Menu";
import Hero from "../../Components/Hero/Hero";
import Tile from "../../Components/Tile/Tile";
import { ButtonVariant } from "../../Components/Button/ButtonVariant";
import RightArrowIcon from "../../Icons/RightArrow.svg";
import SlimHero from "../../Components/SlimHero/SlimHero";

export type paletteType = {
  sho: string;
  hoo: string;
  rgb: string;
  bw: string;
};

export const PALETTE: paletteType = {
  sho: " Roströd med ljusblått",
  hoo: " Mjukt röd med havsblått",
  rgb: " Naturlig färg",
  bw: " Svartvit",
};

const DetailsPage = () => {
  const { slug } = useParams();
  const artwork = artworks.find((a) => Number(a.id).toString() === slug);
  const img = require(`./${artwork?.name}-${artwork?.palettes[0]}-thumb.jpg`);
  const tileImg = require(`./artwork-${artwork?.name}.jpg`);
  const navigate = useNavigate();
  return (
    <>
      <Menu />
      <Hero
        image={img}
        title={artwork?.prettyName ?? ""}
        imageDesktop={img}
        text=""
      />
      <Tile
        image={tileImg}
        title="Om bilden"
        text={
          `${artwork?.infoText}${artwork?.palettes.map((p) => PALETTE[p as keyof paletteType])}` ??
          ""
        }
        buttonText="Anpassa"
        action={() => navigate(`/customise/${artwork?.id}`)}
        variant={ButtonVariant.PRIMARY}
        buttonIcon={RightArrowIcon}
      />
      <SlimHero title="Vetenskaplig information" image={img} />
    </>
  );
};

export default DetailsPage;
