import SlimHero from "../../Components/SlimHero/SlimHero";
import SlimHeroImg from "../../Images/SlimHero2.jpg";

const CheckoutPage = () => {
  return (
    <>
      <SlimHero image={SlimHeroImg} title="Uppgifter" />
      <p>Checkout</p>
    </>
  );
};

export default CheckoutPage;
